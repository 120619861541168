<template>
    <b-row>
        <b-col cols="12">
            <!-- ************************************************************************************** -->
            <!-- *                             UPPER PAGINATION CONTROLS                              * -->
            <!-- ************************************************************************************** -->
            <table-pagination-controls
                :pollsTableTotalPages="pollsTableTotalPages"
                :numberOfPolls="numberOfPolls"
            />

            <!-- Filter by column selection -->
            <b-row class="mx-0 ml-3">
                <b-col class="pl-0">
                    <b>Filter by: </b>
                    <b-form-checkbox-group v-model="FilterTableFields" class="">
                        <b-form-checkbox value="District">{{ getLabel("District") }}</b-form-checkbox>
                        <b-form-checkbox value="Date">{{ getLabel("Date") }}</b-form-checkbox>
                        <b-form-checkbox value="Pollster">{{ getLabel("Pollster") }}</b-form-checkbox>
                        <b-form-checkbox value="Link">{{ getLabel("Link") }}</b-form-checkbox>
                        <b-form-checkbox value="Notes">{{ getLabel("Notes") }}</b-form-checkbox>
                        <b-form-checkbox value="Source">{{ getLabel("Source") }}</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-col>
                <b-col v-if="enableFuturePolls === true" class="pl-0">
                    <data-table-checkbox-filter
                        title="Show Polls"
                        v-model="filterFuturePolls"
                        :initOptions ="['Current', 'Future']"
                        :initSelected ="filterFuturePolls"
                    >
                    </data-table-checkbox-filter>
                    <slot name="filterColRight"></slot>
                </b-col>
            </b-row>

            <!-- ************************************************************************************** -->
            <!-- *                                  POLLS DATA TABLE                                  * -->
            <!-- ************************************************************************************** -->
            <b-row>
                <b-col>
                    <b-table id="polls-table"  hover 
                        :items="pollsFilter" 
                        :details-provider="isRowExpanded"
                        :fields="pollDataTableFields" 
                        :per-page="pollsPerPage"
                        :current-page="pollsTableCurrentPage"
                        :filter="PollsTableFilter"
                        :filterIncludedFields="FilterTableFields"
                        :sort-by.sync="SortBy"
                        :sort-desc.sync="SortDesc"
                        :tbody-tr-class="rowClass"
                        >
                        <template slot="head(D_Topline)"><slot name="head(D_Topline)"><i class='fas fa-democrat'></i></slot></template>
                        <template slot="head(R_Topline)"><slot name="head(R_Topline)"><i class='fas fa-republican'></i></slot></template>
                        <template slot="head(Generic_D)"><slot name="head(Generic_D)"><i class='fas fa-democrat'></i></slot></template>
                        <template slot="head(Generic_R)"><slot name="head(Generic_R)"><i class='fas fa-republican'></i></slot></template>
                        <template slot="head(President_Approval)">
                            <slot name="head(President_Approval)">
                                <!-- Use the short name (eg. Presidential election cycle) -->
                                <span v-if="configHasPresCandDShortName">
                                    {{configGetPresCandDShortName}}
                                </span>
                                <!-- Use thumbs up (eg. Non - Presidential election cycle) -->
                                <span v-else>
                                    <i class="fas fa-thumbs-up"></i>
                                </span>
                            </slot>
                        </template>
                        <template slot="head(President_Disapproval)">
                            <slot name="head(President_Disapproval)">
                                <!-- Use the short name (eg. Presidential election cycle) -->
                                <span v-if="configHasPresCandRShortName">
                                    {{configGetPresCandRShortName}}
                                </span>
                                <!-- Use thumbs down (eg. Non - Presidential election cycle) -->
                                <span v-else>
                                    <i class="fas fa-thumbs-down"></i>
                                </span>
                            </slot>
                        </template>

                        <template v-slot:thead-top="data">
                            <b-tr>
                                <!-- <b-th colspan="3" style="border-top:0;"><span class="sr-only">Poll Info</span></b-th> -->
                                <b-th v-if="displayGenericCandidate === true" colspan="2" style="border-top:0; width:25%">
                                    <b-input-group>
                                        <b-form-input 
                                            id="polls-table-filter-input"
                                            v-model="PollsTableTextFilter"
                                            type="search"
                                            :placeholder="filterInputPlaceholderText"
                                            @keypress="updateDataTablePage"
                                            debounce="500"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!PollsTableTextFilter" @click="PollsTableTextFilter = ''">Clear Filter</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-th>
                                <b-th v-else colspan="3" style="border-top:0;">
                                    <b-input-group>
                                        <b-form-input id="polls-table-filter-input" v-model="PollsTableTextFilter"
                                            type="search" :placeholder="filterInputPlaceholderText" @keypress="updateDataTablePage"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!PollsTableTextFilter" @click="PollsTableTextFilter = ''">Clear Filter</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-th>
                                <b-th colspan="3" variant="primary" class="text-center">Named Candidate</b-th>
                                <b-th v-show="displayGenericCandidate === true" colspan="3" variant="light" class="text-center">Generic Candidate</b-th>
                                <b-th colspan="3" variant="primary" class="text-center">{{ getPresidentialLabel}}</b-th>
                                <b-th colspan="1" variant="" class="text-center" style="border-top:0; min-width:5em;">
                                    <b-link size="sm" class="px-1" @click="ToggleAllRowDetails" id="expand-all-btn"><small>{{ExpandAllText}}</small></b-link>
                                </b-th>
                            </b-tr>
                        </template>

                        <template v-slot:cell(Date)="data" >
                            <span v-html="data.value"></span>
                        </template>

                        <template v-slot:cell(show_details)="row" >
                            <b-button
                                data-old-click="row.toggleDetails"
                                @click="updatePollsBeingEditedState(row)"
                                variant="outline-secondary"
                                class="btn-data-stats py-0 px-1"
                                v-b-popover.hover.left="'Expand/Collapse Details'"
                            >
                                <i :class="toggleDetailsIconClass(row.detailsShowing)" style="color:#666;"></i>
                            </b-button>
                        </template>

                        <template v-slot:row-details="row">
                            <b-card class="polls-table-details-drawer shadow-sm">
                            <b-container fluid class="text-left" style="font-size:smaller;">
                                <b-row>
                                    <b-col>
                                        <b-collapse :id="editPollID(row.item.ID)" class="edit-polls-container">
                                            <edit-poll-form 
                                                :row="row" 
                                                :sources="sources"
                                                :districts="districts"
                                                :pollsterList="pollsterList"
                                                :displayNamedCandidateName="displayNamedCandidateName"
                                                :displayGenericCandidate="displayGenericCandidate" 
                                                :resetNewPollForm="resetNewPollForm"
                                                :alertUserWithToaster="alertUserWithToaster"
                                                :borderClass="borderClass"
                                                :disable-fields="disableFields"
                                                :get-label="getLabel"
                                                :label-map="labelMap"
                                            >
                                                <template v-slot:dToplineCandidateNameLabel><slot name="dToplineCandidateNameLabel">Cand. 1</slot></template>
                                            </edit-poll-form>
                                        </b-collapse>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="5">
                                        <dl class="row m-0 px-0">
                                            <dt v-if="displayNamedCandidateName" class="col col-3 m-0 px-0 border-bottom">
                                                <slot name="dToplineCandidateNameLabel">Cand. 1</slot>:</dt>  
                                            <dd v-if="displayNamedCandidateName" class="col col-9 m-0 px-0 border-bottom">{{ row.item.D_Topline_Candidate_Name }}</dd>

                                            <dt class="col col-3 m-0 px-0 border-bottom">Source:</dt>  
                                            <dd class="col col-9 m-0 px-0 border-bottom">{{ getSourceText(row.item.Source) }}</dd>

                                            
                                            <dt class="col col-3 m-0 px-0 border-bottom">Pollster:</dt>  
                                            <dd class="col col-9 m-0 px-0 border-bottom">{{ row.item.Pollster }}</dd>

                                            <dt class="col col-3 m-0 px-0 border-bottom">Link:</dt>  
                                            <dd class="col col-9 m-0 px-0 border-bottom">{{ row.item.Link }}</dd>
                                            
                                            <dt class="col col-3 m-0 px-0">Notes:</dt>  
                                            <dd class="col col-9 m-0 px-0">{{ row.item.Notes }}</dd>
                                        </dl>
                                    </b-col>
                                    <b-col cols="5">
                                        <dl class="row m-0 px-0">
                                            <dt  v-if="displayRegion"  class="col col-5 m-0 px-0 border-bottom">Region:</dt>  
                                            <dd  v-if="displayRegion" class="col col-7 m-0 px-0 border-bottom">{{ row.item.Region }}</dd>

                                            <dt class="col col-5 m-0 px-0 border-bottom">Entered by:</dt>  
                                            <dd class="col col-7 m-0 px-0 border-bottom">
                                                {{ row.item.Entered_By }}<br><span v-if="row.item.Created_At !== ''">(<i>{{ formatDateWithTime(row.item.Created_At) }}</i>)</span>
                                            </dd>
                                            
                                            <dt class="col col-5 m-0 px-0">Last updated by:</dt>  
                                            <dd class="col col-7 m-0 px-0">
                                                {{ row.item.Last_Updated_By }}<br><span v-if="row.item.Updated_At !== ''">(<i>{{ formatDateWithTime(row.item.Updated_At) }}</i>)</span>
                                            </dd>
                                        </dl>
                                    </b-col>
                                    <b-col cols="2" class="text-right">
                                    </b-col>
                                </b-row>
                            </b-container>
                            </b-card>
                        </template>
                    </b-table>
                </b-col>
            </b-row>

            <!-- ************************************************************************************** -->
            <!-- *                             LOWER PAGINATION CONTROLS                              * -->
            <!-- ************************************************************************************** -->
            <table-pagination-controls
                :pollsTableCurrentPage="pollsTableCurrentPage"
                :pollsPerPage.sync="pollsPerPage"
                :pollsTableTotalPages="pollsTableTotalPages"
                :numberOfPolls="numberOfPolls"
            />
        </b-col>
    </b-row>
</template>

<script>
    import DataTableCheckboxFilter from '../poll-entry/DataTableCheckboxFilter.vue';
    import EditPollForm from '../poll-entry/EditPollForm';
    import TablePaginationControls from '../poll-entry/TablePaginationControls.vue';
    import { storeToRefs, mapState } from 'pinia';

    // Constants
    import { PollConfig } from '../../pollconfig'

    // Stores 
    import { usePollsStore } from '../../stores/polls';

    const pollsStore = usePollsStore();

    // // Store refs
    const {
        polldata,
        filteredPolls,
        pollsTableCurrentPage,
        pollsPerPage,
        numberOfPolls,
        sortBy,
        sortDesc,
        filterTableFields,
        pollsTableTextFilter,
        pollsTablePresetFilter,
        pollsTableFilter,
        GetPollPage,
        lastFilter,
    } = storeToRefs(pollsStore);

    export default {
        components: { 
            DataTableCheckboxFilter,
            EditPollForm,
            TablePaginationControls,
        },
        data() {
            let fields;
            // const pollsStore = usePollsStore();
            if (this.displayGenericCandidate === true) {
                fields = [
                    {key: "District",          sortable: true, label: this.getLabel("District") },
                    {key: "Date",              sortable: true, formatter: 'dateFormatter' },
                    {key: "D_Topline",         sortable: true, variant: 'secondary', label: this.getLabel("D_Topline"), tdClass: 'percentStyle', },
                    {key: "R_Topline",         sortable: true, variant: 'secondary', label: this.getLabel("R_Topline"), tdClass: 'percentStyle', },
                    // {key: "Indp_Other",        sortable: true, variant: 'secondary', label: this.getLabel("Indp_Other"), tdClass: 'percentStyle', },
                    {key: "Unsure",            sortable: true, variant: 'secondary', label: this.getLabel("Unsure"), tdClass: 'percentStyle', },
                    {key: "Generic_D",         sortable: true, label: this.getLabel("Generic_D"), tdClass: 'percentStyle', },
                    {key: "Generic_R",         sortable: true, label: this.getLabel("Generic_R"), tdClass: 'percentStyle', },
                    // {key: "Generic_I",         sortable: true, label: this.getLabel("Generic_I"), tdClass: 'percentStyle', },
                    {key: "Generic_U",         sortable: true, label: this.getLabel("Generic_U"), tdClass: 'percentStyle', },
                    {key: "President_Approval",    sortable: true, variant: 'secondary', label: this.getLabel("President_Approval"), tdClass: 'percentStyle', },
                    {key: "President_Disapproval", sortable: true, variant: 'secondary', label: this.getLabel("President_Disapproval"), tdClass: 'percentStyle',  },
                    {key: "President_Unsure",      sortable: true, variant: 'secondary', label: this.getLabel("President_Unsure"), tdClass: 'percentStyle',  },
                    {key: "show_details",      label: "Details" }
                ];
            }
            else {
                fields = [
                    {key: "District",          sortable: true, label: this.getLabel("District") },
                    {key: "Date",              sortable: true, formatter: 'dateFormatter' },
                    {key: "WeeksOut",          sortable: true },
                    {key: "D_Topline",         sortable: true, variant: 'secondary', label: this.getLabel("D_Topline"), tdClass: 'percentStyle', },
                    {key: "R_Topline",         sortable: true, variant: 'secondary', label: this.getLabel("R_Topline"), tdClass: 'percentStyle', },
                    // {key: "Indp_Other",        sortable: true, variant: 'secondary', label: this.getLabel("Indp_Other"), tdClass: 'percentStyle', },
                    {key: "Unsure",            sortable: true, variant: 'secondary', label: this.getLabel("Unsure"), tdClass: 'percentStyle', },
                    {key: "President_Approval",    sortable: true, variant: 'secondary', label: this.getLabel("President_Approval"), tdClass: 'percentStyle', },
                    {key: "President_Disapproval", sortable: true, variant: 'secondary', label: this.getLabel("President_Disapproval"), tdClass: 'percentStyle',  },
                    {key: "President_Unsure",      sortable: true, variant: 'secondary', label: this.getLabel("President_Unsure"), tdClass: 'percentStyle',  },
                    {key: "show_details",      label: "Details" }
                ];
            }
            return {
                isAllExpanded: false,
                filterFuturePolls: ['Current', 'Future'],
                // pollDataTableFields: ["ID", "District", "Date", "D_Topline", "R_Topline", "Indp_Other", "Unsure", "Generic_D", "Generic_R", "Generic_I", "Generic_U", "President_Approval", "President_Disapproval"],
                pollDataTableFields: fields,
                // pollsStore: pollsStore,
            }
        },
        computed: {
            configHasPresCandDShortName() {
                return PollConfig.configHasPresCandDShortName();
            },
            configHasPresCandRShortName() {
                return PollConfig.configHasPresCandRShortName();
            },
            configGetPresCandDShortName() {
                return PollConfig.presCandDShortName;
            },
            configGetPresCandRShortName() {
                return PollConfig.presCandRShortName;
            },
            getPresidentialLabel() {
                return PollConfig.getPresidentialLabel();
            },
            SortBy: {
                get() {
                    return sortBy.value;
                },
                set(val) {
                    sortBy.value = val;
                }
            },
            SortDesc: {
                get() {
                    return sortDesc.value;
                },
                set(val) {
                    sortDesc.value = val;
                }
            },
            PollsTableFilter: {
                get() {
                    return pollsTableFilter.value;
                },
                set(val) {
                    pollsTableFilter.value = val;
                }
            },
            PollsTableTextFilter: {
                get() {
                    return pollsTableTextFilter.value;
                },
                set(val) {
                    pollsTableTextFilter.value = val;
                    pollsTableFilter.value = pollsTablePresetFilter.value + pollsTableTextFilter.value;
                }
            },
            FilterTableFields: {
                get() {
                    return filterTableFields.value;
                },
                set(val) {
                    filterTableFields.value = val;
                }
            },
            pollsPerPage() {
                return pollsPerPage.value;
            },
            pollsTableCurrentPage() {
                return pollsTableCurrentPage.value;
            },
            pollsTableTotalPages () {
                return Math.ceil(this.numberOfPolls / pollsPerPage.value);
            },
            numberOfPolls () {
                return numberOfPolls.value;
            },
            ExpandAllText () {
                return this.isAllExpanded ? 'Collapse All' : 'Expand  All';
            },
            filterInputPlaceholderText () {
                let text = "Filter by ";
                for (var i = 0; i < filterTableFields.value.length; i++) {
                    text += this.getLabel(filterTableFields.value[i]) + (i == filterTableFields.value.length-2 ? " or " : (i == filterTableFields.value.length-1 ? ". ": ", ") );
                }
                return text;
            }
        },
        methods: {
            async pollsFilter(ctx, callback) {
                await pollsStore.GetPollPage(callback);

                if (this.displayRegion) {
                    let that = this;

                    return filteredPolls.value.filter(function(item) {
                        let polldate        = new Date(item['Date']);
                        let now             = new Date();

                        if (that.filterFuturePolls.includes('Current')) {
                            if (polldate <= now) {
                                return true;
                            }
                        }

                        if (that.filterFuturePolls.includes('Future')) {
                            if (polldate > now) {
                                return true;
                            }
                        }

                        return false;
                    });

                }

                return filteredPolls.value
            },
            formatDateWithTime(dateString) {
                const date = new Date(dateString);
                return date.toLocaleString('en-US', {
                    // timeZone: 'America/New_York',
                    // timeZone: 'Europe/Berlin',
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                });
            },
            formatDate(dateString) {
                const date = new Date(dateString);
                return date.toLocaleDateString('en-US', {
                    // Show the date entered as UTC
                    timeZone: 'UTC',
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
            },
            dateFormatter(value) {
                let polldate        = new Date(value);
                let now             = new Date();

                if (now >= polldate) {
                    return this.formatDate(polldate);
                    // return this.formatDate(polldate) + '<div>' + polldate + '</div>';
                }

                return '<i class="far fa-calendar-alt"></i> ' + this.formatDate(polldate);
            },
            getLabel(key) {
                if (key === "D_Topline") {
                    return this.labelMap["D_Topline"] ? this.labelMap["D_Topline"] : "D" 
                }
                else if (key === "R_Topline") {
                    return this.labelMap["R_Topline"] ? this.labelMap["R_Topline"] : "R" 
                }
                else if (key === "Indp_Other") {
                    return this.labelMap["Indp_Other"] ? this.labelMap["Indp_Other"] : "I/O" 
                }
                else if (key === "Unsure") {
                    return this.labelMap["Unsure"] ? this.labelMap["Unsure"] : "U" 
                }
                else if (key === "Generic_D") {
                    return this.labelMap["Generic_D"] ? this.labelMap["Generic_D"] : "D" 
                }
                else if (key === "Generic_R") {
                    return this.labelMap["Generic_R"] ? this.labelMap["Generic_R"] : "R" 
                }
                else if (key === "Generic_I") {
                    return this.labelMap["Generic_I"] ? this.labelMap["Generic_I"] : "I/O" 
                }
                else if (key === "Generic_U") {
                    return this.labelMap["Generic_U"] ? this.labelMap["Generic_U"] : "U" 
                }
                else if (key === "President_Approval") {
                    return this.labelMap["President_Approval"] ? this.labelMap["President_Approval"] : "Approval" 
                }
                else if (key === "President_Disapproval") {
                    return this.labelMap["President_Disapproval"] ? this.labelMap["President_Disapproval"] : "Disapprove" 
                }
                else if (key === "President_Unsure") {
                    return this.labelMap["President_Unsure "] ? this.labelMap["President_Unsure"] : "U"
                }

                return this.labelMap[key] ? this.labelMap[key] : key;
            },
            getSourceText(source) {
                return PollConfig.getSourceText(source);
            },
            updatePollsBeingEditedState(row) {
                // console.log('+++ updatePollsBeingEditedState( ' + row.item.ID + ' ) ++++');
                row.toggleDetails();
            },
            ToggleAllRowDetails() {
                // console.log("ToggleAllRowDetails");
                this.isAllExpanded = !this.isAllExpanded;
                filteredPolls.value.forEach((el,i) => { el._showDetails = this.isAllExpanded; });
                if( !this.isAllExpanded ) {
                }
            },
            updateDataTablePage () {
                // console.log('** updateDataTablePage() pollsTableFilter = ' + pollsTableFilter.value + ' **');
                if( pollsTableFilter.value !== null && pollsTableFilter.value !== "") {
                    pollsTableCurrentPage.value = 1;
                }
            },
            rowClass(item, type) {
                if (!item || type !== 'row')
                    return;
                // console.log(item);
                let polldate        = new Date(item['Date']);
                let now             = new Date();
                if (polldate <= now) {
                    if (item['D_Topline'] === '-' && 
                        item['R_Topline'] === '-' &&
                        item['Indp_Other'] === '-' &&  
                        item['Unsure'] === '-' &&
                        item['Generic_D'] === '-' &&
                        item['Generic_R'] === '-' &&
                        item['Generic_I'] === '-' &&
                        item['Generic_U'] === '-' &&
                        item['President_Approval'] === '-' &&  
                        item['President_Disapproval'] === '-' &&
                        item['President_Unsure'] === '-'
                    ) {
                        return 'table-danger';
                    }
                    else {
                        return;
                    }
                }

                return;
            },
            DuplicatePoll(row) {
                row.toggleDetails();
                let duplicatePollId = pollsStore.DuplicatePollState(row.item);
                this.$nextTick(() => {
                    this.$root.$emit('bv::toggle::collapse', 'edit-poll-collapse-' + duplicatePollId);
                });
            },
            isRowExpanded(item) {
                return item.detailsExpanded;
            }

        },
        props: {
            districts: Array,
            pollsterList: Array,
            sources: Array,
            disableFields: {required: false, default: () => ([]), type: Array},
            enableFuturePolls: {required: false, default: false, type: Boolean},
            displayNamedCandidateName: {required: false, default: true, type: Boolean},
            displayGenericCandidate: {required: false, default: true, type: Boolean},
            displayRegion: {required: false, default: false, type: Boolean},
            alertUserWithToaster: Function,
            borderClass: Function,
            editPollID: Function,
            resetNewPollForm: Function,
            toggleDetailsIconClass: Function,
            UpdateEditPollState: Function,
            labelMap: {required: false, 
                        default: () => ({}),
                        type: Object},
        }
    }
</script>

<style scoped>
.table-footer-style {
    font-style: italic;
    font-size: small;
}
</style>
<style>
#polls-table thead tr {
    font-size: large;
    text-align: center;
}
#polls-table tbody tr:hover  td.table-secondary {
    background-color: #cccdce !important;
}
#polls-table tbody tr:hover  td.table-gray-400 {
    background-color: #d5d6d7 !important;
}
#polls-table tbody tr.table-danger:hover  td.table-secondary {
    background-color: #faa0a0 !important;
}
#polls-table tbody tr.table-danger:hover  td.table-gray-400 {
    background-color: #fbadad !important;
}

#polls-table tbody tr td {
    font-size: larger;
    text-align: center;
}
.columnStyle,
.percentStarStyle,
.percentStyle {
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace!important;
    text-align:right !important;
}
.percentStyle:after {
    content: "%";
}
#polls-table tbody tr td:last-child {
    text-align: center !important;
}
#polls-table tbody tr td:last-child:after {
    content: "";
}
#polls-table .polls-table-details-drawer {
    margin-top: -12px;
    border-top-left-radius: 0;
    border-top: 0;
    border-top-right-radius: 0;
    background-color: #e7e8e9;
}
#polls-table tr.b-table-details td {
    padding-bottom: 0px;
}
</style>
