<template>
    <div>
        <!-- *********************************************** EDIT POLL UI *********************************************** -->
        <b-row>
            <b-col>
                <h4 v-if="!isDuplicateID(row.item.ID)"><i><b>Edit Poll #{{ row.item.ID }} - {{ row.item.District }}</b></i></h4>
                <h4 v-else><i><b>Duplicate Poll - {{ row.item.District }}</b></i></h4>
            </b-col>
            <!-- Edit Date -->
            <b-col cols="3" class="pr-0">
                <b-form-group class=" ">
                    <label class="sr-only" for="editpoll-date">Poll Date</label>
                    <b-input-group prepend="Date" class="text-right">
                        <b-input required id="editpoll-date" type="date" v-model="editDate" class="mb-2 mr-sm-2 mb-sm-0" style="height:calc(1.5em + 0.75rem + 2px);"
                            @blur="ValidateDateInput" :class="borderClass(isValidDate)"
                        ></b-input>                    
                    </b-input-group>
                    <div v-if="!isValidDate" class="text-center" style="width:100%;">
                        <p class="alert alert-danger text-danger font-italic p-1 px-3 mx-0 mr-2 mb-0 mt-3">{{enterPollDateText}}</p>
                    </div>
                </b-form-group>
            </b-col>
            <!-- Edit Source -->
            <b-col cols="3" class="pl-1 pr-0">
                <b-form-group class=" ">
                    <label class="sr-only" for="editpoll-source">Source</label>
                    <b-input-group prepend="Source" class="text-right">
                      <b-form-select required id="editpoll-source" v-model="row.item.Source" :options="sources"></b-form-select>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-show="this.row.item.PollType === 'General' || this.row.item.PollType === undefined">
            <b-col cols="4">
                <b-row>
                    <b-col class="text-center">
                        <label class="lead">Named Candidate</label> <!-- EDIT NAMED CANDIDATE -->
                    </b-col>
                </b-row>
                <b-row>
                    <b-form-group class="col pl-0 pr-1 ccl-newpoll-number-input" role="named-group">
                        <label class="d-block text-center" for="editpoll-d-topline"><i class="fas fa-democrat"></i><span class="sr-only">Democrat</span></label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidNamedCandidate)" >
                          <b-input id="editpoll-d-topline" type="number" v-model="row.item.D_Topline" placeholder=""></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group class="col pl-0 pr-1 ccl-newpoll-number-input" >
                        <label class="d-block text-center" for="editpoll-r-topline"><i class="fas fa-republican"></i><span class="sr-only">Republican</span></label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidNamedCandidate)" >
                          <b-input id="editpoll-r-topline" type="number" v-model="row.item.R_Topline" placeholder="" ></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group v-if="!disableFields.includes('Indp_Other')" class="col pl-0 pr-1 ccl-newpoll-number-input"  >
                        <label class="small" for="editpoll-io-topline">Indp./Other</label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidNamedCandidate)" >
                          <b-input id="editpoll-io-topline" type="number" v-model="row.item.Indp_Other" placeholder="" ></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group class="col pl-0 pr-2 ccl-newpoll-number-input"  >
                        <label class="small" for="editpoll-u-topline">Undecided</label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidNamedCandidate)" >
                          <b-input id="editpoll-u-topline" type="number" v-model="row.item.Unsure" placeholder="" ></b-input>
                        </b-input-group>
                    </b-form-group>
                </b-row>
                <b-row>
                    <div v-if="!isValidNamedCandidate" class="text-center" style="width:100%;">
                        <p class="alert alert-danger text-danger font-italic p-1 mr-2">Named candidate fields should add up to 100%.</p>
                    </div>
                    <!-- <b-alert id="alert-named-candidate-inverted" class="ccl-potentially-inverted-alert rounded-0 mr-2 py-1 px-2" v-model="isNamedCandidatePotentiallyInverted" variant="warning" dismissible>
                        <p class="p-1 mr-5 mb-0">Looks like these numbers could be inverted.  Please double check.</p>
                        <template slot="dismiss" st><b-button variant="warning" ><i class="fas fa-check"></i>&nbsp;OK</b-button></template>
                    </b-alert> -->
                </b-row>
                <b-row v-if="this.displayNamedCandidateName">
                    <b-form-group class="col pl-0 pr-1 ccl-newpoll-number-input" role="named-group">
                        <label class="d-block" for="editpoll-d-topline-candidate-name"><slot name="dToplineCandidateNameLabel">Candidate 1</slot></label>
                        <b-input-group class="mb-2 mr-sm-2 mb-sm-0 text-left" >
                            <b-input 
                                id="editpoll-d-topline-candidate-name"
                                placeholder="(optional)"
                                v-model="row.item.D_Topline_Candidate_Name"                                                        
                            >
                            </b-input>
                        </b-input-group>
                    </b-form-group>
                </b-row>
            </b-col>
            <b-col v-show="displayGenericCandidate === true" cols="4">
                <b-row>
                    <b-col class="text-center">
                        <label class="lead">Generic Candidate</label>  <!-- EDIT GENERIC CANDIDATE -->
                    </b-col>
                </b-row>
                <b-row>
                    <b-form-group class="col pl-2 pr-1 ccl-newpoll-number-input">
                        <label class="d-block text-center" for="editpoll-get-d-topline"><i class="fas fa-democrat"></i><span class="sr-only">Democrat</span></label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidGenericCandidate)">
                          <b-input id="editpoll-get-d-topline" type="number" v-model="row.item.Generic_D" placeholder=""></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group class="col pl-0 pr-1 ccl-newpoll-number-input">
                        <label class="d-block text-center" for="editpoll-gen-r-topline"><i class="fas fa-republican"></i><span class="sr-only">Republican</span></label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidGenericCandidate)">
                          <b-input id="editpoll-gen-r-topline" type="number" v-model="row.item.Generic_R" placeholder=""></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group v-if="!disableFields.includes('Generic_I')" class="col pl-0 pr-1 ccl-newpoll-number-input">
                        <label class="small" for="editpoll-gen-io-topline">Indp./Other</label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidGenericCandidate)">
                          <b-input id="editpoll-gen-io-topline" type="number" v-model="row.item.Generic_I" placeholder=""></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group class="col pl-0 pr-0 ccl-newpoll-number-input">
                        <label class="small" for="editpoll-gen-u-topline">Undecided</label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidGenericCandidate)">
                          <b-input id="editpoll-gen-u-topline" type="number" v-model="row.item.Generic_U" placeholder=""></b-input>
                        </b-input-group>
                    </b-form-group>
                </b-row>
                <b-row>
                    <div v-if="!isValidGenericCandidate" class="text-center" style="width:100%;">
                        <p class="alert alert-danger text-danger font-italic p-1 ml-2">Generic candidate fields should add up to 100%.</p>
                    </div>
                    <!-- <b-alert class="ccl-potentially-inverted-alert rounded-0 ml-2 py-1 px-2" v-model="isGenericCandidatePotentiallyInverted" variant="warning" dismissible>
                        <p class="p-1 mr-5 mb-0">Looks like these numbers could be inverted.  Please double check.</p>
                        <template slot="dismiss" st><b-button variant="warning" ><i class="fas fa-check"></i>&nbsp;OK</b-button></template>
                    </b-alert> -->
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <b-col class="text-center">
                        <label class="lead">{{ getPresidentialLabel }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-form-group class="col pl-4 pr-1 ccl-newpoll-number-input">
                        <label class="small" :class="configHasPresCandDShortName ? '' : 'd-block text-center'" for="editpoll-djt-approve-topline">
                            <!-- Use the short name (eg. Presidential election cycle) -->
                            <span v-if="configHasPresCandDShortName">
                                {{configGetPresCandDShortName}}
                            </span>
                            <!-- Use thumbs up (eg. Non - Presidential election cycle) -->
                            <span v-else>
                                <i class="fas fa-thumbs-up"></i>
                            </span>
                        </label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right"  :class="borderClass(isValidPresidentApproval)">
                          <b-input id="editpoll-djt-approve-topline" type="number" v-model="row.item.President_Approval" placeholder=""></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group class="col pl-0 pr-1 ccl-newpoll-number-input">
                        <label class="small" :class="configHasPresCandRShortName ? '' : 'd-block text-center'" for="editpoll-djt-disapprove-topline">
                            <!-- Use the short name (eg. Presidential election cycle) -->
                            <span v-if="configHasPresCandRShortName">
                                {{configGetPresCandRShortName}}
                            </span>
                            <!-- Use thumbs down (eg. Non - Presidential election cycle) -->
                            <span v-else>
                                <i class="fas fa-thumbs-down"></i>
                            </span>
                        </label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right"  :class="borderClass(isValidPresidentApproval)">
                          <b-input id="editpoll-djt-disapprove-topline" type="number" v-model="row.item.President_Disapproval" placeholder=""></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group class="col pl-0 pr-0 ccl-newpoll-number-input">
                        <label class="small" for="editpoll-djt-unsure-topline">Undecided</label>
                        <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right"  :class="borderClass(isValidPresidentApproval)">
                          <b-input id="editpoll-djt-unsure-topline" type="number" v-model="row.item.President_Unsure" placeholder=""></b-input>
                        </b-input-group>
                    </b-form-group>
                </b-row>
                <b-row>
                    <div v-if="!isValidPresidentApproval" class="text-center" style="width:100%;">
                        <p class="alert alert-danger text-danger font-italic p-1 ml-4">Should add up to 100%.</p>
                    </div>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-show="this.row.item.PollType === 'Primary'">
            <b-row>
                <b-col class="text-left mb-2">
                    <label class="lead">Primary Candidates</label>
                </b-col>
            </b-row>
            <b-row>
                <primary-candidate-form-input
                    :index="0"
                    :candidateNameLabel="row.item.C1_NameLabel"
                    :candidateName.sync="row.item.C1_Name"
                    :percentage.sync="row.item.C1_Percentage"
                >
                </primary-candidate-form-input>
                <primary-candidate-form-input
                    :index="1"
                    :candidateNameLabel="row.item.C2_NameLabel"
                    :candidateName.sync="row.item.C2_Name"
                    :percentage.sync="row.item.C2_Percentage"
                >
                </primary-candidate-form-input>
                <primary-candidate-form-input
                    :index="2"
                    :candidateNameLabel="row.item.C3_NameLabel"
                    :candidateName.sync="row.item.C3_Name"
                    :percentage.sync="row.item.C3_Percentage"
                >
                </primary-candidate-form-input>
                <primary-candidate-form-input
                    :index="3"
                    :candidateNameLabel="row.item.C4_NameLabel"
                    :candidateName.sync="row.item.C4_Name"
                    :percentage.sync="row.item.C4_Percentage"
                >
                </primary-candidate-form-input>
            </b-row>
        </b-row>
        <b-row v-if="this.row.item.PollType === 'Primary'">
            <b-form-group class=" col-12 col-sm-5 pl-0 pr-1 mb-2">
                <b-input-group prepend="Primary Date" class="text-right">
                    <b-input
                        id="editpoll-primary-date"
                        type="date"
                        v-model="row.item.PrimaryDate" class="mb-2 mr-sm-2 mb-sm-0" 
                        :class="borderClass(isValidPrimaryDate)"
                        style="height:calc(1.5em + 0.75rem + 2px);"
                        @blur="ValidateDateInput"
                    ></b-input>
                    <div v-if="!isValidPrimaryDate" class="text-center" style="width:100%;">
                        <p class="alert alert-danger text-danger font-italic p-1 px-2 mx-0 mb-0 mt-3">Enter a valid primary date from 2021 or 2022.</p>
                    </div>
                    <div v-if="!isPrimaryAfterPollDate" class="text-center" style="width:100%;">
                        <p class="alert alert-danger text-danger font-italic p-1 px-2 mx-0 mb-0 mt-3">Poll date is after primary date</p>
                    </div>
                </b-input-group>
            </b-form-group>
        </b-row>
        <b-row>
            <b-col cols="10" class="pl-0 pr-2">
                <b-row>
                    <!-- Edit Pollster -->
                    <b-col cols="4" class="pl-3 pr-0">
                        <b-form-group class=" ">
                            <label class="sr-only" for="editpoll-pollster">Pollster</label>
                            <b-input-group prepend="Pollster" class="text-right">
                              <!-- <b-input id="editpoll-pollster" type="text" v-model="row.item.Pollster" placeholder=""></b-input> -->
                              <b-form-input list="editpoll-pollster-input-list" id="editpoll-pollster" v-model="row.item.Pollster" type="text" placeholder="(optional)"></b-form-input>
                              <b-form-datalist id="editpoll-pollster-input-list" :options="pollsterList"></b-form-datalist>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <!-- Edit Link -->
                    <b-col cols="4" class="pl-3 pr-0">
                        <b-form-group class=" ">
                            <label class="sr-only" for="editpoll-link">Link</label>
                            <b-input-group prepend="Link" class="text-right">
                              <b-input id="editpoll-link" type="text" v-model="row.item.Link" placeholder=""></b-input>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <!-- Edit Notes -->
                    <b-col cols="4" class="pl-3 pr-2">
                        <b-form-group class=" ">
                            <label class="sr-only" for="editpoll-notes">Notes</label>
                            <b-input-group prepend="Notes" class="text-right">
                              <b-input id="editpoll-notes" type="text" v-model="row.item.Notes" placeholder=""></b-input>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>

            <!-- Edit Submit -->
            <b-col cols="2" class="pl-4 pr-0">
                <b-button v-if="!isDuplicateID(row.item.ID)" block id="editpoll-submit-btn" variant="secondary" :disabled="!isUpdatePollButtonActive" @click="UpdateOrSavePoll(row.item.ID, row.item, update = true)">UPDATE POLL</b-button>
                <div v-else>
                    <b-button block id="editpoll-duplicate-poll-save-btn" variant="primary" :disabled="!isUpdatePollButtonActive" @click="UpdateOrSavePoll(row.item.ID, row.item, update = false)">SAVE</b-button>
                    <b-button block id="editpoll-duplicate-poll-cancel-btn" variant="secondary" :disabled="!isUpdatePollButtonActive" @click="cancelDuplicatePoll(row.item.ID)">CANCEL</b-button>
                </div>
            </b-col>
        </b-row>
        <!-- *********************************************** /EDIT POLL UI *********************************************** -->
    </div>
</template>
<script>
    import PrimaryCandidateFormInput from './PrimaryCandidateFormInput.vue';
    import { storeToRefs } from 'pinia';
    
    // Constants
    import { PollConfig } from '../../pollconfig';

    // Stores 
    import { usePollsStore } from '../../stores/polls';

    const pollsStore = usePollsStore();

    // // Store refs
    const { duplicatePolls,
            FormatPollDataForBTable } = storeToRefs(pollsStore);

    // Destructure actions
    const { CheckForDuplicates, RemovePollById } = pollsStore;

    export default {
        components: {
            PrimaryCandidateFormInput,
        },
        props: {
            row: { required: true },
            disableFields: {required: false, default: () => ([]), type: Array},
            displayNamedCandidateName: {required: false, default: false, type: Boolean},
            displayGenericCandidate: {required: false, default: true, type: Boolean},
            resetNewPollForm: { required: true },
            alertUserWithToaster: { required: true },
            borderClass: { required: true },
            getLabel: Function,
            labelMap: {required: false, 
                        default: () => ({}),
                        type: Object},
        },
        methods: {
            cancelDuplicatePoll(poll_id) {
                let okayHandler = () => {
                    // console.log("cancel-duplicate-poll-modal-okay event");
                    CCLEvent.$off('cancel-duplicate-poll-modal-okay', okayHandler);
                    CCLEvent.$off('cancel-duplicate-poll-modal-canceled', canceledHandler);
                    // Remove the duplicate poll from the store
                    RemovePollById(poll_id);
                };
                let canceledHandler = () => {
                    // console.log("cancel-duplicate-poll-modal-canceled event");
                    CCLEvent.$off('cancel-duplicate-poll-modal-okay', okayHandler);
                    CCLEvent.$off('cancel-duplicate-poll-modal-canceled', canceledHandler);
                };
                CCLEvent.$on('cancel-duplicate-poll-modal-okay', okayHandler);
                CCLEvent.$on('cancel-duplicate-poll-modal-canceled', canceledHandler);
                CCLEvent.$emit('cancel-duplicate-poll');
            },
            // Using vue2 with pinia seems to require wrappers to put the store values in the template
            // There maybe a better way to do this or can remove this when we move to Vue3
            isDuplicateID(id) {
                return pollsStore.isDuplicateID(id);
            },
            getPayload(poll_data, pollData) {
                return {
                    PollType: poll_data.PollType,
                    District: PollConfig.getDistrictValueByText(poll_data.District),
                    Date: this.editDate,
                    D_Topline: poll_data.D_Topline===''? '':Number(poll_data.D_Topline)/100,
                    R_Topline: poll_data.R_Topline===''? '':Number(poll_data.R_Topline)/100,
                    Indp_Other: poll_data.Indp_Other===''? '':Number(poll_data.Indp_Other)/100,
                    Unsure: poll_data.Unsure===''? '':Number(poll_data.Unsure)/100,
                    Gen_D: poll_data.Generic_D===''? '':Number(poll_data.Generic_D)/100,
                    Gen_R: poll_data.Generic_R===''? '':Number(poll_data.Generic_R)/100,
                    Gen_Oth: poll_data.Generic_I===''? '':Number(poll_data.Generic_I)/100,
                    Gen_Undec: poll_data.Generic_U===''? '':Number(poll_data.Generic_U)/100,
                    Pres_App: poll_data.President_Approval===''? '':Number(poll_data.President_Approval)/100,
                    Pres_Disap: poll_data.President_Disapproval===''? '':Number(poll_data.President_Disapproval)/100,
                    Pres_Unsure: poll_data.President_Unsure ===''? '':Number(poll_data.President_Unsure)/100,
                    Source: poll_data.Source,
                    Pollster: poll_data.Pollster,
                    Link: poll_data.Link,
                    Notes: poll_data.Notes,
                    PollData: JSON.stringify(pollData),
                };
            },
            ValidateDateInput () {
                // console.log('%c ValidateDateInput', 'background-color:gray; color:#0f0; font-size:16px;');
                let polldate        = new Date(this.editDate),
                    primaryDate        = new Date(this.row.item.PrimaryDate),
                    now             = new Date(),
                    oldest_polldate = new Date(PollConfig.pollEntryStartDate),
                    election_polldate = new Date(PollConfig.electionDate)

                    ;
                // console.log('%c    polldate: ' + polldate + ', now: ' + now, 'background-color:gray; color:#0f0; font-size:16px;');
                this.isValidDate = polldate < now && polldate >= oldest_polldate;  
                this.isValidPrimaryDate = (primaryDate < election_polldate && primaryDate >= oldest_polldate) || this.row.item.PrimaryDate === '' || this.row.item.PrimaryDate == null;
                this.isPrimaryAfterPollDate = (primaryDate >= polldate) || (this.row.item.PrimaryDate === '') || this.row.item.PrimaryDate == null || (polldate === '');

            },
            UpdateOrSavePoll: function (poll_id, poll_data, update = true) {
                // console.log('%c UpdateOrSavePoll:' + 
                //             poll_id +
                //             ' poll_data:: ' +
                //             'poll_data:' +
                //             JSON.stringify(poll_data, null, 4) +
                //             ' update = ' +
                //             update,
                //             'color:#0f0; background-color:#000; font-size:16px;');


                let pollData = '';

                if (poll_data.PollType === 'Primary') {
                    pollData = {
                            primaryData: [
                                            { sequenceId: 0, 
                                              candidateName: this.row.item.C1_Name, 
                                              percentage: (this.row.item.C1_Percentage === '-') ? null : this.row.item.C1_Percentage 
                                            }, 
                                            { sequenceId: 1,
                                              candidateName: this.row.item.C2_Name,
                                              percentage: (this.row.item.C2_Percentage === '-') ? null : this.row.item.C2_Percentage 
                                            },
                                            { sequenceId: 2,
                                              candidateName: this.row.item.C3_Name,
                                              percentage: (this.row.item.C3_Percentage === '-') ? null : this.row.item.C3_Percentage 
                                            },
                                            { sequenceId: 3,
                                              candidateName: this.row.item.C4_Name,
                                              percentage: (this.row.item.C4_Percentage === '-') ? null : this.row.item.C4_Percentage 
                                            },
                                        ]
                    };
                    pollData['primaryDate'] = this.row.item.PrimaryDate;
                }
                else {
                    pollData = {
                        generalData: {
                            dToplineCandidateName: this.row.item.D_Topline_Candidate_Name
                        }
                    }
                }

                let payload =  this.getPayload(poll_data, pollData);
                let duplicates = CheckForDuplicates(poll_data);

                if (update) {
                    this.UpdatePoll(poll_id, payload);
                }
                else if (!duplicates) {
                    this.SaveDuplicatePoll(poll_id, payload);
                }
                else if (duplicates) {
                    let okayHandler = () => {
                        console.log("check-for-duplicates-modal-okay event");
                        CCLEvent.$off('check-for-duplicates-modal-okay', okayHandler);
                        CCLEvent.$off('check-for-duplicates-modal-canceled', canceledHandler);
                        this.SaveDuplicatePoll(poll_id, payload);
                    };
                    let canceledHandler = () => {
                        console.log("check-for-duplicates-modal-canceled event");
                        CCLEvent.$off('check-for-duplicates-modal-okay', okayHandler);
                        CCLEvent.$off('check-for-duplicates-modal-canceled', canceledHandler);
                    };
                    CCLEvent.$on('check-for-duplicates-modal-okay', okayHandler);
                    CCLEvent.$on('check-for-duplicates-modal-canceled', canceledHandler);
                    CCLEvent.$emit('duplicate-polls-found');
                }
            },
            SaveDuplicatePoll: function (poll_id, payload) {
                console.log('%c SaveDuplicatePoll' + "- payload - " + JSON.stringify(payload, null, 4), 'color:#0f0; background-color:#000; font-size:18px;')
                let that = this;


                axios.post('/polls/polls', payload)
                    .then(function (response) {
                        // console.log('%c StorePoll RESPONSE', 'color:#0f0; background-color:#000; font-size:18px;', response);
                        that.polldata = pollsStore.FormatPollDataForBTable(response.data.polls);
                        CCLEvent.$emit('update-poll-data', response.data.polls);
                        if( response.data.status = 'Sucess' ) {
                            that.resetNewPollForm();
                            that.alertUserWithToaster('Duplicate poll successfully saved!', 'success');    
                            CCLEvent.$emit('reset-poll-edit-ui', poll_id);
                        } else {
                            that.alertUserWithToaster('Error: Unable to save duplicate poll. ' + response.data.message , 'danger');
                        }
                    })
                    .catch(function (error) {
                        that.alertUserWithToaster('ERROR: Unable to communicate with server.', 'danger');
                        console.log('%c StorePoll ERROR ', 'color:#0f0; background-color:#f00; font-size:22px;', error);
                    });
            },
            UpdatePoll: function (poll_id, payload) {
                // console.log('%c UpdatePoll :' + poll_id + ' payload:: '  + 'payload:' + JSON.stringify(payload, null, 4), 'color:#0f0; background-color:#000; font-size:16px;')
                let that = this;
                axios
                    .put('/polls/polls/'+ poll_id, payload)
                    .then( response => {
                        console.log('%c ** UpdatePoll.put.then  response: ', 'color:#0f0; background-color:#000; font-size:18px;', response);
                        CCLEvent.$emit('update-poll-data', response.data.polls);
                        if( response.data.status = 'Success' ) {
                            that.resetNewPollForm();
                            that.alertUserWithToaster('New poll successfully updated!', 'success');  
                            CCLEvent.$emit('reset-poll-edit-ui', poll_id);
                        } else {
                            that.alertUserWithToaster('Error: Unable to update new poll. ' + response.data.message , 'danger');
                        }
                    })
                    .catch(function (error) {
                        that.alertUserWithToaster('ERROR: Unable to communicate with server.', 'danger');
                        console.log('%c UpdatePoll ERROR ', 'color:#0f0; background-color:#f00; font-size:22px;', error);
                    })
                    ;
            },
        },
        computed: {
            enterPollDateText() {
                return 'Enter a valid ' + PollConfig.electionYear + ' poll date.';
            },
            configHasPresCandDShortName() {
                return PollConfig.configHasPresCandDShortName();
            },
            configHasPresCandRShortName() {
                return PollConfig.configHasPresCandRShortName();
            },
            configGetPresCandDShortName() {
                return PollConfig.presCandDShortName;
            },
            configGetPresCandRShortName() {
                return PollConfig.presCandRShortName;
            },
            getPresidentialLabel() {
                return PollConfig.getPresidentialLabel();
            },
            isUpdatePollButtonActive () {
                // console.log(this.isValidNamedCandidate && this.isValidGenericCandidate && this.isValidPresidentApproval  && this.isValidDate && this.isValidPrimaryDate && this.isPrimaryAfterPollDate); 
                return this.isValidNamedCandidate && this.isValidGenericCandidate && this.isValidPresidentApproval  && this.isValidDate && this.isValidPrimaryDate && this.isPrimaryAfterPollDate; 
            },
            isNamedCandidateBlank () { 
                let D = this.row.item.D_Topline === "-" ? "" : this.row.item.D_Topline;
                let R = this.row.item.R_Topline === "-" ? "" : this.row.item.R_Topline;
                let I = this.row.item.Indp_Other === "-" ? "" : this.row.item.Indp_Other;
                let U = this.row.item.Unsure === "-" ? "" : this.row.item.Unsure;
                return D === "" && R === "" && I === "" && U === ""; 
            },
            isGenericCandidateBlank () { 
                let D = this.row.item.Generic_D === "-" ? "" : this.row.item.Generic_D;
                let R = this.row.item.Generic_R === "-" ? "" : this.row.item.Generic_R;
                let I = this.row.item.Generic_I === "-" ? "" : this.row.item.Generic_I;
                let U = this.row.item.Generic_U === "-" ? "" : this.row.item.Generic_U;
                return D === "" && R === "" && I === "" && U === ""; 
                // return this.row.item.Generic_D === "-" && this.row.item.Generic_R === "-" && this.row.item.Generic_I === "-" && this.row.item.Generic_U === "-"; 
            },
            isPresBlank () {
                let approve    = this.row.item.President_Approval === "-" ? "" : this.row.item.President_Approval;
                let disapprove = this.row.item.President_Disapproval === "-" ? "" : this.row.item.President_Disapproval;
                let unsure     = this.row.item.President_Unsure === "-" ? "" : this.row.item.President_Unsure;
                return approve === "" && disapprove === "" && unsure === "";
            },
            namedCandidateSum () { 
                return Math.round( Number(this.row.item.D_Topline === '-' ? 0 : this.row.item.D_Topline) + 
                                   Number(this.row.item.R_Topline === '-' ? 0 : this.row.item.R_Topline) + 
                                   Number(this.row.item.Indp_Other === '-' ? 0 : this.row.item.Indp_Other) + 
                                   Number(this.row.item.Unsure === '-' ? 0 : this.row.item.Unsure) ); 
            },
            genericCandidateSum () { 
                return Math.round( Number(this.row.item.Generic_D === '-' ? 0 : this.row.item.Generic_D) + 
                                   Number(this.row.item.Generic_R === '-' ? 0 : this.row.item.Generic_R) + 
                                   Number(this.row.item.Generic_I === '-' ? 0 : this.row.item.Generic_I) + 
                                   Number(this.row.item.Generic_U === '-' ? 0 : this.row.item.Generic_U) ); 
            }, 
            isValidNamedCandidate () {
                // console.log("isValidNamedCandidate ==> namedCandidateSum: " + this.namedCandidateSum + ', isNamedCandidateBlank:' + this.isNamedCandidateBlank + ' ; ');
                return (this.namedCandidateSum <= 101 && this.namedCandidateSum >= 99) || this.isNamedCandidateBlank;
            },
            isValidGenericCandidate () {
                // console.log("isValidGenericCandidate ==> genericCandidateSum: " + this.genericCandidateSum + ', isGenericCandidateBlank:' + this.isGenericCandidateBlank + ' ; ');
                return (this.genericCandidateSum <= 101 && this.genericCandidateSum >= 99 ) || this.isGenericCandidateBlank || this.displayGenericCandidate === false;
            },
            isValidPresidentApproval () {
                // let is_djt_blank = this.row.item.President_Approval === "" && this.row.item.President_Disapproval === "";
                let djt_sum = Math.round( Number(this.row.item.President_Approval === '-' ? 0 : this.row.item.President_Approval) +
                                          Number(this.row.item.President_Disapproval === '-' ? 0 : this.row.item.President_Disapproval) +
                                          Number(this.row.item.President_Unsure === '-' ? 0 : this.row.item.President_Unsure)
                                        ); 
                // console.log("isValidPresidentApproval ==> djt_sum: " + djt_sum + ', isPresBlank:' + this.isPresBlank + ' ; ');
                return (djt_sum <= 101 && djt_sum >= 99) || this.isPresBlank;
            }
        },
        data() {
            return {
                districts: PollConfig.districts,
                sources: PollConfig.sources,
                pollsterList: PollConfig.pollsterList,
                isValidDate: true,
                isValidPrimaryDate: true,
                isPrimaryAfterPollDate: true,
                // Use a local variable for the date to avoid the table from resorting
                // until user has submit their changes
                editDate: this.row.item.Date,
                // isValidNamedCandidate: true,
                // isValidGenericCandidate: true,
                // isValidPresidentApproval: true,
            }
        }
    };
</script>