<template>
    <div>
        <b-container>
            <card-layout
                title=""
            >
                <!-- <h3 class="mt-2 mb-0 pb-2" style="xxxmargin-left:-15px;">Active Polls: {{ numberOfPolls }}</h3> -->
                <polls-data-table
                    :enableFuturePolls=false
                    :displayNamedCandidateName=false
                    :displayGenericCandidate=true
                    :alertUserWithToaster="alertUserWithToaster"
                    :borderClass="borderClass"
                    :toggleDetailsIconClass="toggleDetailsIconClass"
                    :editPollID="editPollID"
                    :FormatPollDataForBTable="FormatPollDataForBTable"
                    :label-map="{ 'President_Approval':'Harris', 'President_Disapproval': 'Trump', 'President_Unsure':'Unsure'}"
                    :disable-fields="['Indp_Other', 'Generic_I']"
                ></polls-data-table>
            </card-layout>
        </b-container>
    </div>
</template>

<script>
    import PollsDataTable from './poll-viewer/PollsDataTable.vue';
    import { storeToRefs } from 'pinia';

    // Stores
    import { usePollsStore } from '../stores/polls';
    import CardLayout from './details/CardLayout.vue';

    const pollsStore = usePollsStore();

    // // Store refs
    const { 
        numberOfPolls,
        pollsTableTextFilter,
        pollsTablePresetFilter,
        pollsTableFilter,
    } = storeToRefs(pollsStore);

    export default {
        components: { PollsDataTable, CardLayout },
        data() {
            return {
            }
        },
        computed: {
            numberOfPolls () {
                return numberOfPolls.value;
            },
        },
        methods: {
            alertUserWithToaster (message, variant=null) {
                this.$bvToast.toast(message, {
                    title: message,
                    variant: variant,
                    toaster: 'b-toaster-top-center',
                    solid: true
                });
            },
            borderClass( state ) {
                return (state ? '' : 'border border-danger ');
            },
            editPollID(id) {
                return String() + 'edit-poll-collapse-' + id;
            },
            FormatPollDataForBTable (polls) {
                let items = [];
                Object.keys(polls).forEach(function(i) {
                    items.push({
                        "ID"                : polls[i].pollId,
                        "PollType"          : 'General',
                        "District"          : polls[i].district,
                        "Date"              : polls[i].polldate,
                        "D_Topline"         : polls[i].dcand === null        ? String() + '-' :((Number(polls[i].dcand)        * 100).toFixed(2) * 1).toString(),
                        "R_Topline"         : polls[i].rcand === null        ? String() + '-' :((Number(polls[i].rcand)        * 100).toFixed(2) * 1).toString(),
                        "Indp_Other"        : polls[i].icand === null        ? String() + '-' :((Number(polls[i].icand)        * 100).toFixed(2) * 1).toString(),
                        "Unsure"            : polls[i].unsure === null       ? String() + '-' :((Number(polls[i].unsure)       * 100).toFixed(2) * 1).toString(),
                        "Generic_D"         : polls[i].genD === null         ? String() + '-' :((Number(polls[i].genD)         * 100).toFixed(2) * 1).toString(),
                        "Generic_R"         : polls[i].genR === null         ? String() + '-' :((Number(polls[i].genR)         * 100).toFixed(2) * 1).toString(),
                        "Generic_I"         : polls[i].genI === null         ? String() + '-' :((Number(polls[i].genI)         * 100).toFixed(2) * 1).toString(),
                        "Generic_U"         : polls[i].genUnsure === null    ? String() + '-' :((Number(polls[i].genUnsure)    * 100).toFixed(2) * 1).toString(),
                        "President_Approval"    : polls[i].presApprove === null ? String() + '-' :((Number(polls[i].presApprove) * 100).toFixed(2) * 1).toString(),
                        "President_Disapproval" : polls[i].presNeg === null     ? String() + '-' :((Number(polls[i].presNeg)     * 100).toFixed(2) * 1).toString(),
                        "President_Unsure"      : polls[i].presUnsure === null   ? String() + '-' :((Number(polls[i].presUnsure)   * 100).toFixed(2) * 1).toString(),
                        "C1_Percentage"     : NaN,
                        "C2_Percentage"     : NaN,
                        "C3_Percentage"     : NaN,
                        "C4_Percentage"     : NaN,
                        "C1_Name"           : '',
                        "C2_Name"           : '',
                        "C3_Name"           : '',
                        "C4_Name"           : '',
                        "C1_NameLabel"      : '',
                        "C2_NameLabel"      : '',
                        "C3_NameLabel"      : '',
                        "C4_NameLabel"      : '',
                        "Entered_By"        : polls[i].enteredBy,
                        "Last_Updated_By"   : polls[i].lastUpdatedBy,
                        "Source"            : polls[i].pollsource,
                        "Pollster"          : polls[i].pollster,
                        "Link"              : polls[i].link,
                        "Notes"             : polls[i].notes,
                        "Updated_At"        : polls[i].updated_at,
                        "Created_At"        : polls[i].created_at,
                        "_showDetails"      : false
                    });
                })
                return items;
            },
            toggleDetailsIconClass: function (row_details_showing) {
                return (row_details_showing) ? "fas fa-caret-up" : "fas fa-caret-down";
            },
        },
        mounted() {
            console.log('PollsApp Component mounted.');

            // Update the lastFilter state from outside Vue
            CCLEvent.$on('update-last-filter', (lastfilter) => {
                pollsTablePresetFilter.value = lastfilter;
                pollsTableFilter.value = pollsTableTextFilter.value + lastfilter;
                // console.log(pollsTableFilter.value);
             });
        }
    }
</script>
<style>
</style>