<template>
    <b-col cols="12" lg="12" xl="12">
        <b-row> 
            <b-form-group class="col col-8 col-sm-6 col-lg-4 pl-0 pr-1 ccl-newpoll-number-input" >
                <label class="d-block" :for="`candidate-name-${index}`">{{candidateNameLabel}}</label>
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0 text-left" :class="borderClass(isValidName)" >
                    <b-input 
                        :id="`candidate-name-${index}`"
                        :disabled="disableForm"
                        :value="candidateName"
                        @input="$emit('update:candidateName',$event)"
                    >
                    </b-input>
                </b-input-group>
                <div v-if="!isValidName" class="text-center" style="width:100%;">
                    <p class="alert alert-danger text-danger font-italic p-1 mx-0 mb-0 mt-3">Enter a valid candidate</p>
                </div>
            </b-form-group>
            <b-form-group class="col col-4 col-sm-3 col-lg-2 pl-0 pr-1 ccl-newpoll-number-input" role="named-group">
                <label class="d-block" :for="`candidate-percentage-${index}`">Percentage</label>
                <b-input-group append="%" class="mb-2 mr-sm-2 mb-sm-0 text-right" :class="borderClass(isValidPercentage)" >
                    <b-input
                        :id="`candidate-percentage-${index}`"
                        type="number"
                        :value="percentage"
                        :disabled="disableForm"
                        @input="$emit('update:percentage', parseInt($event, 10))"
                        placeholder=""></b-input>
                </b-input-group>
                <div v-if="!isValidPercentage" class="text-center" style="width:100%;">
                    <p class="alert alert-danger text-danger font-italic p-1 mx-0 mb-0 mt-3">Enter a percentage greater than or equal to 0</p>
                </div>
            </b-form-group>
        </b-row>
    </b-col>
</template>

<script>
    export default {
        computed: {
            isValidName () {
                if (!this.candidateName.match(/^\s+$/i) || this.candidateName === '') {
                    return true;
                }
                return false;
            },
            isValidPercentage() {
                if (this.percentage >= 0 || 
                    this.percentage === undefined || 
                    isNaN(this.percentage) ||
                    this.percentage === '')
                  return true;
            },
        },
        methods: {
            borderClass( state ) {
                return (state ? '' : 'border border-danger ');
            },
        },
        props: {
            index: {required: true, type: Number},
            candidateNameLabel: {required: true, type: String},
            candidateName: {required: true, type: String},
            percentage: {required: true, type: [Number, String]},
            disableForm: {required: false, default:false, type: Boolean},
        }
    }
</script>

<style>

</style>
