<template>
        <div>
            <b>{{title}}:</b>
            <b-form-checkbox-group 
                v-model="selected" 
                :options="checkboxOptions" 
                class=""
            >
            </b-form-checkbox-group>
        </div>
</template>

<script>
    export default {
        data() {
            return {
                checkboxOptions: this.initOptions,
                selected: this.initSelected,
            }
        },
        props: {
            title: {required: false, default: "", type: String},
            initOptions: {required: false, default: () => [], type: Array},
            initSelected: {required: false, default: () => [], type: Array},
        },
        watch: {
            selected(val) {
                // console.log(val);
                this.$emit("input", val);
            }
        },
    };
</script>
