<template>
    <div>
        <b-card v-if="displayCard">
            <b-card-body>
                <h6 class="card-title" v-if="displayTitle">{{ title }}</h6>
                <slot></slot>
            </b-card-body>
        </b-card>
        <div v-else>
            <h6 class="card-title" v-if="displayTitle">{{ title }}</h6>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {required: false, default: 'Head To Head', type: String},
            displayCard: {required: false, default: true, type: Boolean},
            displayTitle: {required: false, default: true, type: Boolean},
        },
        mounted() {
            // console.log(`Card "${this.title}" Component mounted.`);
        },
    };
</script>
<style>
</style>