<template>
    <!-- ************************************************************************************** -->
    <!-- *                             PAGINATION CONTROLS                                    * -->
    <!-- ************************************************************************************** -->
    <b-row class="mb-1">
        <b-col cols="12">
            <label class="text-center d-block">
                <span class="xxxsr-only">Page {{ PollsTableCurrentPage }} of {{ pollsTableLastPage }}</span>&nbsp;
            </label>
        </b-col>
        <b-col cols="3">
            <b-form-group
                label="Polls per page"
                label-for="perPageSelect"
                label-cols="4"
                class="mb-0"
            >
                <b-form-select
                    v-model="PollsPerPage"
                    id="perPageSelect"
                    :options="pollsPerPageOptions"
                ></b-form-select>
            </b-form-group>
        </b-col>
        <b-col cols="9">
            <b-pagination
                v-model="PollsTableCurrentPage"
                :total-rows="pollsTableNumberOfPolls"
                :per-page="PollsPerPage"
                limit="7"
                align="fill"
                aria-controls="polls-table"
                first-text="« First Page"
                last-text="Last Page »"
                prev-text="‹ Previous Page"
                next-text="Next Page ›"
            ></b-pagination>
        </b-col>
    </b-row>
</template>

<script>
    import { storeToRefs, mapState } from 'pinia';

    // Stores
    import { usePollsStore } from '../../stores/polls';

    const pollsStore = usePollsStore();

    // // Store refs
    const {
        pollsTableCurrentPage,
        pollsPerPage,
        pollsPerPageOptions,
        pollsTableNumberOfPolls,
        numberOfPolls,
    } = storeToRefs(pollsStore);

    export default {
        computed: {
            pollsTableLastPage: {
                get() {
                    return Math.ceil(numberOfPolls.value / pollsPerPage.value);
                },
            },
            pollsTableNumberOfPolls: {
                get() {
                    return pollsTableNumberOfPolls.value;
                },
            },
            PollsPerPage: {
                get() {
                    return pollsPerPage.value;
                },
                set(val) {
                    pollsPerPage.value = val;
                }
            },
            pollsPerPageOptions: {
                get() {
                    return pollsPerPageOptions.value;
                },
            },
            pollsTableCurrentPage() {
                return pollsTableCurrentPage.value;
            },
            PollsTableCurrentPage: {
                get() {
                    return pollsTableCurrentPage.value;
                },
                set(val) {
                    pollsTableCurrentPage.value = val;
                }
            },
            numberOfPolls: {
                get() {
                    return numberOfPolls.value;
                },
            },
        },
        watch: {
            pollsPerPage(newVal) {
                pollsPerPage.value = newVal;
            },
            pollsTableCurrentPage(newVal) {
                pollsTableCurrentPage.value = newVal;
            }
        }
    }
</script>

<style>

</style>
