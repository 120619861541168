import { filter } from 'lodash';
import { defineStore } from 'pinia'

// Constants
import { PollConfig } from '../pollconfig'

export const usePollsStore = defineStore('Polls', {
  state: () => ({
    polldata: [],
    duplicatePolls: [],
    passedDuplicateTest: true,
    tmpIDCount: 1, // Used to create unique duplicate ids
    pollDataFilterField: null,
    pollDataFilterOptions: [],
    pollsTableCurrentPage: 1,
    pollsTableLastPage: 1,
    pollsTableNumberOfPolls: 0,
    pollsPerPage: 20,
    pollsPerPageOptions: [5, 10, 20, 50, 100, 200],
    filterTableFields: ["District", "Date"],
    pollsTableFilter: '',
    pollsTableTextFilter: '', // text filter
    pollsTablePresetFilter: '', // button based filter
    numberOfPolls: 0,
    sortBy: "Date",
    sortDesc: true,
    sortByColToJSON: {
      "ID"                : 'pollId',
      "District"          : 'district',
      "Date"              : 'polldate',
      "D_Topline"         : 'dcand',
      "R_Topline"         : 'rcand',
      "Indp_Other"        : 'icand',
      "Unsure"            : 'unsure',
      "Generic_D"         : 'genD',
      "Generic_R"         : 'genR',
      "Generic_I"         : 'genI',
      "Generic_U"         : 'genUnsure',
      "President_Approval"    : 'presApprove',
      "President_Disapproval" : 'presNeg',
      "President_Unsure"      : 'presUnsure',
      "Entered_By"        : 'enteredBy',
      "Last_Updated_By"   : 'lastUpdatedBy',
      "Source"            : 'pollsource',
      "Pollster"          : 'pollster',
      "Link"              : 'link',
      "Notes"             : 'notes',
      "Updated_At"        : 'updated_at',
      "Created_At"        : 'created_at',
    },
    formatDateWithTime(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
      });
    },
    FormatPollDataForBTable (polls) {
      let items = [];
      Object.keys(polls).forEach(function(i) {
          items.push({
              "ID"                : polls[i].pollId,
              "PollType"          : 'General',
              // "District"          : polls[i].district,
              "District"          : PollConfig.getDistrictByValue(polls[i].district),
              "Date"              : polls[i].polldate,
              "D_Topline"         : polls[i].dcand === null        ? String() + '-' :((Number(polls[i].dcand)        * 100).toFixed(2) * 1).toString(),
              "R_Topline"         : polls[i].rcand === null        ? String() + '-' :((Number(polls[i].rcand)        * 100).toFixed(2) * 1).toString(),
              "Indp_Other"        : polls[i].icand === null        ? String() + '-' :((Number(polls[i].icand)        * 100).toFixed(2) * 1).toString(),
              "Unsure"            : polls[i].unsure === null       ? String() + '-' :((Number(polls[i].unsure)       * 100).toFixed(2) * 1).toString(),
              "Generic_D"         : polls[i].genD === null         ? String() + '-' :((Number(polls[i].genD)         * 100).toFixed(2) * 1).toString(),
              "Generic_R"         : polls[i].genR === null         ? String() + '-' :((Number(polls[i].genR)         * 100).toFixed(2) * 1).toString(),
              "Generic_I"         : polls[i].genI === null         ? String() + '-' :((Number(polls[i].genI)         * 100).toFixed(2) * 1).toString(),
              "Generic_U"         : polls[i].genUnsure === null    ? String() + '-' :((Number(polls[i].genUnsure)    * 100).toFixed(2) * 1).toString(),
              "President_Approval"    : polls[i].presApprove === null ? String() + '-' :((Number(polls[i].presApprove) * 100).toFixed(2) * 1).toString(),
              "President_Disapproval" : polls[i].presNeg === null     ? String() + '-' :((Number(polls[i].presNeg)     * 100).toFixed(2) * 1).toString(),
              "President_Unsure"      : polls[i].presUnsure === null   ? String() + '-' :((Number(polls[i].presUnsure)   * 100).toFixed(2) * 1).toString(),
              "C1_Percentage"     : NaN,
              "C2_Percentage"     : NaN,
              "C3_Percentage"     : NaN,
              "C4_Percentage"     : NaN,
              "C1_Name"           : '',
              "C2_Name"           : '',
              "C3_Name"           : '',
              "C4_Name"           : '',
              "C1_NameLabel"      : '',
              "C2_NameLabel"      : '',
              "C3_NameLabel"      : '',
              "C4_NameLabel"      : '',
              "Entered_By"        : polls[i].enteredBy,
              "Last_Updated_By"   : polls[i].lastUpdatedBy,
              "Source"            : polls[i].pollsource,
              "Pollster"          : polls[i].pollster,
              "Link"              : polls[i].link,
              "Notes"             : polls[i].notes,
              "Updated_At"        : polls[i].updated_at,
              "Created_At"        : polls[i].created_at,
              "_showDetails"      : false
          });
      })
      return items;
    },
  }),
  getters: {
    filteredPolls() {
      // If filters are set, use the filtered data
      if (this.pollDataFilterField !== null && this.pollDataFilterOptions.length > 0) {
        let that = this;
        let filtered = this.polldata.filter(function(poll) {
          return that.pollDataFilterOptions.includes(poll[that.pollDataFilterField]);
        });
        return this.polldata.filter(function(poll) {
          return that.pollDataFilterOptions.includes(poll[that.pollDataFilterField]);
        });
      }

      // Otherwise return all the polldata
      return this.polldata;
    }
  },
  actions: {
    // Helper method for detecting if a poll id is a duplicate
    isDuplicateID(poll_id) {
      if (String(poll_id).startsWith('tmp')) {
          return true;
      }
      return false;
    },
    // Remove the temporary poll id from the list of polls
    RemovePollById(poll_id) {
      // console.log('@RemovePollById( poll_id = '+ poll_id + ')');
      let poll_index = this.polldata.map(function(poll){return poll.ID;}).indexOf(poll_id);
      this.polldata.splice(poll_index, 1);
    },
    // Adds a copy of the poll with a temporary poll id to the list of polls
    DuplicatePollState(poll) {
      // console.log('@DuplicatePollState( poll='+JSON.stringify(poll, null, 4)+' )');

      let poll_index = this.polldata.map(function(poll){return poll.ID;}).indexOf(poll.ID);

      let pollCopy = { ...this.polldata[poll_index] };
      pollCopy.ID = "tmp" + poll.ID + "_" + this.tmpIDCount++;

      pollCopy.Entered_By = '';
      pollCopy.Last_Updated_By = '';
      pollCopy.Updated_At = '';
      pollCopy.Created_At = '';
      pollCopy._showDetails = true;

      // Insert poll
      this.polldata.splice(poll_index + 1, 0, pollCopy);
      return pollCopy.ID;
    },
    CheckForDuplicates (newpoll) {
      let duplicate_polls = this.polldata.filter((poll, index) => {
          // console.log('%c filtering polldata ['+index+'] - poll.Date:'+poll.Date+', that.newpoll.Date:'+that.newpoll.Date+', - poll.District:'+poll.District+', that.newpoll.District:'+that.newpoll.District+';; ', 'color:red, font-size:18px;');
          let duplicate = poll.Date === newpoll.Date &&
                          poll.District === newpoll.District &&
                          (
                              (
                                  poll.D_Topline  === newpoll.D_Topline  &&
                                  poll.R_Topline  === newpoll.R_Topline  //&&
                                  // poll.Indp_Other === newpoll.Indp_Other &&
                                  // poll.Unsure     === newpoll.Unsure
                              )  ||
                              (
                                  poll.Generic_D === newpoll.Generic_D &&
                                  poll.Generic_R === newpoll.Generic_R //&&
                                  // poll.Generic_I === that.newpoll.Generic_I &&
                                  // poll.Generic_U === that.newpoll.Generic_U
                              )  
                          );
          if (duplicate) {
              // console.log('Found Duplicate! poll:', poll);
          }
          return duplicate;
      });
      if (duplicate_polls.length > 0) {
          this.duplicatePolls = duplicate_polls;
          return true;
      } else {
          this.duplicatePolls = [];
          return false;
      }
    },
    async GetPollPage(callback) {
      
      // Helper function to covert columns to field names
      const convertTableColsToFields = (cols) => {
        return cols.map(col => this.sortByColToJSON[col] || col);
      };

      try {
          let url = window.ccl.routes.GetPaginatedPolls(this.pollsTableCurrentPage,
                    this.pollsPerPage,
                    this.pollsTablePresetFilter,
                    this.sortByColToJSON[this.sortBy],
                    this.sortDesc,
                    this.pollsTableTextFilter,
                    convertTableColsToFields(this.filterTableFields));

          // console.log(url);

          const response = await axios.get(url);
          // console.log(response.data);

          // Update the polldata
          this.polldata = this.FormatPollDataForBTable(response.data.polls);
          this.pollsTableCurrentPage = response.data.current_page;
          this.pollsTableLastPage = response.data.last_page;
          this.pollsTableNumberOfPolls = response.data.total_filtered;
          this.pollsPerPage = response.data.per_page;
          this.numberOfPolls = response.data.total

          // callback(this.polldata);
          return this.polldata;
      } catch (error) {
          console.log('Error retrieving polls for ' + this.pollsTableCurrentPage);
          // callback([]);
          return [];
      }

      // return null;
      return [];
    }
  },
});