
/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

import './bootstrap';
import './plugins';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
// import VCalendar from 'v-calendar';
import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(BootstrapVue);
// Vue.use(VCalendar);

window.CCLEvent = new Vue({pinia});
Vue.component('polls-app', require('./components/PollsApp.vue').default);
const polls_app_vue = new Vue({ el: document.querySelector('#polls-app-container') });

/*window.Vue = require('vue');

// Add the Vue components for API authentication
Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue')
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue')
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue')
);

const app = new Vue({
    el: '#app'
});*/